import QuestionsListData from "../Data/QuestionsData.json";
import React, { useState } from "react";
import FinalReportBackgroundInfo from "./FinalReportBackgroundInfo.js";
import FinalReportVersionInfo from "./FinalReportVersionInfo.js";
import Topics from "./Topics.js";
import MainContentsQuestionnairePageOption from "../MainContentsQuestionnairePageOption";
import FinalReportQuestionMain from "./FinalReportQuestionMain.js";
import PrintHeader from "./PrintHeader.js";
import { BiSortDown } from "react-icons/bi";
import { BsArrowsCollapse } from "react-icons/bs";
import { GrSettingsOption } from "react-icons/gr";
import RiverFunctionCategoryWithQuestionArray from "../Data/RiverFunctionCategoryWithQuestionArray.json";
import RiverFunctionWithQuestionArray from "../Data/RiverFunctionWithQuestionArray.json";
import RiverFunctionChartCategory from "../RiverFunctionChartCategory.js";
import {
  getRevisedQuestionWithAnswers,
  getRiverFunctionSummary,
  getRiverFunctionCategorySummary,
} from "../Utilities/Util.js";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import PageTitle from "../PageTitle.js";
import SequentialPageNav from "../SequentialPageNav/SequentialPageNav.js";

const FinalReportDetail = () => {
  document.title = global.config.title + ": Final Report";
  const getDefaultOption = (optionItem) => {
    if (optionItem === "isShowDetail")
      return localStorage.getItem(optionItem) === null
        ? false
        : localStorage.getItem(optionItem) === "true";
    else if (optionItem === "isShowQuestionJustification")
      return localStorage.getItem(optionItem) === null
        ? global.config.chart.show_question_justification
        : localStorage.getItem(optionItem) === "true";

    return localStorage.getItem(optionItem) === null
      ? "percentage"
      : localStorage.getItem(optionItem); // percentage or id
  };
  const [
    sortOptionForRiverFunctionCategory,
    setSortOptionForRiverFunctionCategory,
  ] = useState(getDefaultOption("rfc_sort_order"));
  const [sortOptionForRiverFunction, setSortOptionForRiverFunction] = useState(
    getDefaultOption("rf_sort_order")
  );
  const [isShowRiverFunctions, setIsShowRiverFunctions] = useState(true); // this is to display/hide River Functions under Categories
  const [isShowTopicsFunctions, setIsShowTopicsFunctions] = useState(true);
  const [isShowFinalReportQuestions, setIsShowFinalReportQuestions] =
    useState(true);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [isShowQuestionsUnderCategory, setIsShowQuestionsUnderCategory] =
    useState(false);
  const [isShowQuestionJustification, setIsShowQuestionJustification] =
    useState(getDefaultOption("isShowQuestionJustification"));

  document.title = global.config.title + ": Final Report";

  const answerJson = getRevisedQuestionWithAnswers(QuestionsListData);
  const riverFunctionSummary = getRiverFunctionSummary(
    RiverFunctionWithQuestionArray,
    answerJson
  );
  const riverFunctionCategorySummary = getRiverFunctionCategorySummary(
    RiverFunctionCategoryWithQuestionArray,
    answerJson
  );
  const setDetailShowOption = (showDetailOption) => {
    setIsShowDetail(showDetailOption);
    localStorage.setItem("isShowDetail", showDetailOption); // 'true', 'false'
  };

  const sortRiverFunctionCategoryHandler = (sortOption, e) => {
    setSortOptionForRiverFunctionCategory(sortOption);
    localStorage.setItem("rfc_sort_order", sortOption);
  };

  const sortRiverFunctionHandler = (sortOption, e) => {
    setSortOptionForRiverFunction(sortOption);
    localStorage.setItem("rf_sort_order", sortOption);
  };

  const saveQuestionJustification = (bShowJustification) => {
    setIsShowQuestionJustification(bShowJustification);
    localStorage.setItem("isShowQuestionJustification", bShowJustification); // 'true', 'false'
    global.config.chart.show_question_justification = bShowJustification;
  };

  const sortRiverFunctionCategories = (riverFunctionCategories, sortBy) => {
    console.log(riverFunctionCategories);
    // sort by value
    if (sortBy === "percentage") {
      riverFunctionCategories.sort(function (a, b) {
        if (b.yes_percentage === a.yes_percentage)
          return a.unc_percentage - b.unc_percentage;

        return b.yes_percentage - a.yes_percentage;
      });
    } else if (sortBy === "id") {
      riverFunctionCategories.sort(function (a, b) {
        return a.category_order_index - b.category_order_index;
      });
    }

    return riverFunctionCategories;
  };
  return (
    <div className='main-content'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12'>
            <div className='content'>
              <PageTitle title='Report of Potential Environmental Impacts' />
              <div>&nbsp;</div>
              <FinalReportVersionInfo />
              <FinalReportBackgroundInfo />
              <div style={{ pageBreakBefore: "always" }}>
                <PrintHeader />
                <Topics
                  answerJson={answerJson}
                  isShowTopicsFunctions={isShowTopicsFunctions}
                />
              </div>
              <table className='river-function'>
                <thead>
                  <tr>
                    <th scope='col' colSpan='3'>
                      River Functions
                    </th>
                    <th scope='col'>
                      Percentage of questions answered "Yes", "No", or
                      "Uncertain"
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortRiverFunctionCategories(
                    riverFunctionCategorySummary,
                    sortOptionForRiverFunctionCategory
                  ).map((rfc, i) => (
                    <RiverFunctionChartCategory
                      key={i}
                      rfc={rfc}
                      riverFunctionSummary={riverFunctionSummary}
                      answerJson={answerJson}
                      isShowQuestionsUnderCategory={
                        isShowQuestionsUnderCategory
                      }
                      riverFunctionSortOption={sortOptionForRiverFunction}
                      isShowDetail={isShowDetail}
                      isShowRiverFunctions={isShowRiverFunctions}
                    />
                  ))}
                </tbody>
              </table>
              {/*<RiverFunctionSummary sortOption="percentage"></RiverFunctionSummary>*/}
              <FinalReportQuestionMain
                isShowFinalReportQuestions={isShowFinalReportQuestions}
              />
            </div>
            {/*  End of Content */}
          </div>
          <div className='col-xl-2 col-lg-0 col-md-12 col-sm-12 col-12'>
            <div className='sticky-top'>
              {/* Page Option in Canvas for River Function Chart */}
              <div
                style={{ marginTop: "20px" }}
                title='Page Display options'
                className='no-print'
              >
                <button
                  type='button'
                  className='btn btn-outline-secondary btn-sm'
                  style={{ width: "130px" }}
                  data-bs-toggle='offcanvas'
                  data-bs-target='#offcanvasOptions_detail'
                  aria-controls='offcanvasOptions_detail'
                >
                  <GrSettingsOption /> Page Option
                </button>
              </div>
              <MainContentsQuestionnairePageOption />

              <div
                className='offcanvas offcanvas-end'
                tabIndex='-1'
                id='offcanvasOptions_detail'
                aria-labelledby='offcanvasOptionLabel'
              >
                <div className='offcanvas-header'>
                  <h5 className='offcanvas-title' id='offcanvasOptionLabel'>
                    <GrSettingsOption /> Options
                  </h5>
                  <button
                    type='button'
                    className='btn-close text-reset'
                    data-bs-dismiss='offcanvas'
                    aria-label='Close'
                  ></button>
                </div>

                <div className='offcanvas-body'>
                  <div className='card'>
                    <div className='card-header'>
                      <BsArrowsCollapse /> Show/Hide Detail
                    </div>
                    <div className='card-body small'>
                      <div
                        onClick={(e) =>
                          setIsShowRiverFunctions(!isShowRiverFunctions)
                        }
                        style={{ marginBottom: "20px" }}
                      >
                        Show/Hide River Functions under Categories<br></br>
                        {/* https://gitbrent.github.io/bootstrap-switch-button-react/ */}
                        <BootstrapSwitchButton
                          checked={isShowRiverFunctions}
                          onlabel='Displayed'
                          offlabel='Hidden'
                          width={150}
                          height={30}
                          onstyle='primary'
                          offstyle='secondary'
                        />
                      </div>

                      <div
                        onClick={(e) =>
                          setIsShowTopicsFunctions(!isShowTopicsFunctions)
                        }
                        style={{ marginBottom: "20px" }}
                      >
                        Show/Hide Topics<br></br>
                        {/* https://gitbrent.github.io/bootstrap-switch-button-react/ */}
                        <BootstrapSwitchButton
                          checked={isShowTopicsFunctions}
                          onlabel='Displayed'
                          offlabel='Hidden'
                          width={150}
                          height={30}
                          onstyle='primary'
                          offstyle='secondary'
                        />
                      </div>

                      <div
                        onClick={(e) =>
                          setIsShowFinalReportQuestions(
                            !isShowFinalReportQuestions
                          )
                        }
                        style={{ marginBottom: "20px" }}
                      >
                        Show/Hide Documentation of All Responses Detail<br></br>
                        {/* https://gitbrent.github.io/bootstrap-switch-button-react/ */}
                        <BootstrapSwitchButton
                          checked={isShowFinalReportQuestions}
                          onlabel='Displayed'
                          offlabel='Hidden'
                          width={150}
                          height={30}
                          onstyle='primary'
                          offstyle='secondary'
                        />
                      </div>

                      <div
                        onClick={(e) =>
                          setIsShowQuestionsUnderCategory(
                            !isShowQuestionsUnderCategory
                          )
                        }
                        style={{ marginBottom: "20px" }}
                      >
                        Show/Hide questions under each Category
                        <br />
                        {/* https://gitbrent.github.io/bootstrap-switch-button-react/ */}
                        <BootstrapSwitchButton
                          checked={isShowQuestionsUnderCategory}
                          onlabel='Displayed'
                          offlabel='Hidden'
                          width={150}
                          height={30}
                          onstyle='primary'
                          offstyle='secondary'
                        />
                      </div>

                      <div
                        onClick={(e) => setDetailShowOption(!isShowDetail)}
                        style={{ marginBottom: "20px" }}
                      >
                        Show/Hide questions under each River Function.<br></br>
                        <BootstrapSwitchButton
                          checked={isShowDetail}
                          onlabel='Displayed'
                          offlabel='Hidden'
                          width={150}
                          height={30}
                          onstyle='primary'
                          offstyle='secondary'
                        />
                      </div>

                      <div
                        style={{ marginBottom: "20px" }}
                        onClick={(e) =>
                          saveQuestionJustification(
                            !isShowQuestionJustification
                          )
                        }
                      >
                        Show question justification when question is being
                        displayed<br></br>
                        <BootstrapSwitchButton
                          checked={isShowQuestionJustification}
                          onlabel='Displayed'
                          offlabel='Hidden'
                          width={150}
                          height={30}
                          onstyle='primary'
                          offstyle='secondary'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='card' style={{ marginTop: "20px" }}>
                    <div className='card-header'>
                      <BiSortDown></BiSortDown>Sort Options
                    </div>
                    <div className='card-body small'>
                      <h5 className='card-title'>
                        Sort River Function Categories
                      </h5>

                      <div>
                        <input
                          type='radio'
                          id='sortOption2'
                          checked={
                            sortOptionForRiverFunctionCategory === "percentage"
                          }
                          name='sortOptionRiverFunctionCategory'
                          onChange={(e) =>
                            sortRiverFunctionCategoryHandler("percentage", e)
                          }
                        ></input>
                        <label
                          htmlFor='sortOption2'
                          style={{ marginLeft: "10px" }}
                          title='Sort by %Yes Descending, then by %Uncertain Descending'
                        >
                          By Percentage
                        </label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          id='sortOption1'
                          checked={sortOptionForRiverFunctionCategory === "id"}
                          name='sortOptionRiverFunctionCategory'
                          onChange={(e) =>
                            sortRiverFunctionCategoryHandler("id", e)
                          }
                        ></input>
                        <label
                          htmlFor='sortOption1'
                          style={{ marginLeft: "10px" }}
                        >
                          By Category Code
                        </label>
                      </div>
                    </div>

                    <div className='card-body small'>
                      <h5 className='card-title'>Sort River Functions</h5>
                      <div>
                        <input
                          type='radio'
                          id='sortOption4'
                          checked={sortOptionForRiverFunction === "percentage"}
                          name='sortOptionRiverFunction'
                          onChange={(e) =>
                            sortRiverFunctionHandler("percentage", e)
                          }
                        ></input>
                        <label
                          htmlFor='sortOption4'
                          style={{ marginLeft: "10px" }}
                          title='Sort by %Yes Descending, then by %Uncertain Descending'
                        >
                          By Percentage
                        </label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          id='sortOption3'
                          checked={sortOptionForRiverFunction === "id"}
                          name='sortOptionRiverFunction'
                          onChange={(e) => sortRiverFunctionHandler("id", e)}
                        ></input>
                        <label
                          htmlFor='sortOption3'
                          style={{ marginLeft: "10px" }}
                        >
                          River Function Code
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End of Container */}
      </div>
      <SequentialPageNav prev='/Prioritization' next='/Next' />
      {/* End of Main-Content */}
    </div>
  );
};

export default FinalReportDetail;
