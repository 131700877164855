import { useState } from "react";
import RiverFunctionCategoryData from "../Data/RiverFunctionCategoryWithQuestionArray.json";

export default function useSortRFICategories() {
  const [alphabeticalRFCats, setAlphabeticalRFCats] = useState([]);

  const getSortedRFICatsAlphabetically = () => {
    const sortedData = RiverFunctionCategoryData.sort((a, b) => {
      const rfiCatNameA = a.river_function_category_name.toUpperCase();
      const rfiCatNameB = b.river_function_category_name.toUpperCase();
      const sorted =
        rfiCatNameA < rfiCatNameB ? -1 : rfiCatNameA > rfiCatNameB ? 1 : 0;
      console.log(sorted);
      return sorted;
    });
    console.log("SD:", sortedData);
    setAlphabeticalRFCats(sortedData);
  };

  return {
    alphabeticalRFCats,
    getSortedRFICatsAlphabetically,
  };
}
