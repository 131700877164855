import React, { useEffect } from "react";
import styled from "styled-components";

import Collapsible from "../Collapsible";
import RFICategory from "../RFICategory";
import useSortRFICategories from "../hooks/useSortRFICategories";

const RiverFunctionIndicatorsStyled = styled.section`
  width: 100%;
  height: auto;
`;

export default function RiverFunctionIndicators() {
  const { alphabeticalRFCats, getSortedRFICatsAlphabetically } =
    useSortRFICategories();

  useEffect(() => {
    getSortedRFICatsAlphabetically();
  }, [getSortedRFICatsAlphabetically]);
  return (
    <RiverFunctionIndicatorsStyled>
      {alphabeticalRFCats && alphabeticalRFCats.length > 0
        ? alphabeticalRFCats.map((rfi, index) => (
            <Collapsible
              bgColor={rfi.bgColor}
              borderColor={rfi.borderColor}
              centerTitle={true}
              content={rfi.rfiData}
              itemIndex={index}
              key={rfi.river_function_category_id}
              title={`${rfi.river_function_category_name} (${rfi.river_function_category_code})`}
            >
              <RFICategory
                bgColor={rfi.bgColor}
                borderColor={rfi.borderColor}
                categoryId={rfi.river_function_category_id}
                definition={rfi.definition_txt}
                impact={rfi.impact_txt}
              />
            </Collapsible>
          ))
        : null}
    </RiverFunctionIndicatorsStyled>
  );
}
