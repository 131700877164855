module.exports = global.config = {
  title: "ORNL",
  page_title: "Environmental Decision Support Toolkit",
  version: "6.10.1",
  chart: {
    chart_type: "bgcolor" /* 'bgcolor' or 'image' */,
    test_mode: true /* this is testing mode to show the percentage information */,
    show_question_justification: true,
  },
  allow_clear_answer: true,

  show_question_debug_info: false,
  show_question_id_for_development: false /* this show/hide question_id next to the question text */,

  questionnaire: {
    option_choice_id: {
      yes: 21,
      no: 22,
      uncertain: 23,
      not_applicable: 24,
    },
    local_storage_last_answer_timestamp_name: "LatestAnswer_TimeStamp",
  },

  prioritization: {
    min_yes_percentage: 0.5,
    min_unc_percentage: 0.5,
    local_storage_prioritization_timestamp_name: "Prioritization_TimeStamp",
    KeyTopicCategory: "KeyTopics",
    UncertainTopicCategory: "UncertainTopics",
  },
};
